import React from "react";
import { SbBlokData, storyblokEditable } from "gatsby-source-storyblok";
import { Box, Text, VStack, LinkBox, LinkOverlay } from "@chakra-ui/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImage } from "gatsby-plugin-storyblok-image";

import { StoryblokImage } from "../types/StoryblokImage";
import { StoryblokRichtextContent } from "storyblok-rich-text-react-renderer";
import RichText from "./RichText";
import SmartLink from "./SmartLink";
import { StoryblokLink } from "../types/StoryblokLink";

type Props = {
  blok: SbBlokData & {
    name: string;
    role: string;
    bio: StoryblokRichtextContent;
    image: StoryblokImage;
    link: StoryblokLink;
  };
};

function StaffMember({ blok }: Props) {
  const gatsbyImageData = blok.image.filename
    ? getGatsbyImage(blok.image.filename, {
        layout: "constrained",
        quality: 80,
        fitIn: false,
        width: 800,
        height: 800,
        breakpoints: [750, 1080, 1366, 1920],
        smartCrop: true,
      })
    : null;

  const NameWrapper = blok.link?.cached_url ? LinkOverlay : Box;

  return (
    <VStack {...storyblokEditable(blok)} spacing={4}>
      <LinkBox>
        <VStack spacing={4} textAlign="center">
          {blok.image?.filename && gatsbyImageData && (
            <Box>
              <GatsbyImage
                alt={blok.image.alt}
                image={gatsbyImageData}
                objectFit="cover"
                style={{
                  borderRadius: "50%",
                }}
                imgStyle={{
                  borderRadius: "50%",
                }}
              />
            </Box>
          )}

          <NameWrapper
            href={blok.link?.cached_url}
            target={blok.link?.cached_url ? "_blank" : undefined}
          >
            <Text as="u" fontSize="xl" fontWeight={600}>
              {blok.name}
            </Text>
          </NameWrapper>
        </VStack>

        <Text color="brandYellow" textAlign="center">
          {blok.role}
        </Text>
      </LinkBox>

      <RichText body={blok.bio} />
    </VStack>
  );
}

export default StaffMember;
