import React from "react";

type Props = {
  blok: {
    embedCode: string;
  };
};

const DynamicEmbeddableContent = ({ blok }: Props) => {
  return <div dangerouslySetInnerHTML={{ __html: blok.embedCode }} />;
};

export default DynamicEmbeddableContent;
