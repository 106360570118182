import React from "react";
import { PageProps, graphql } from "gatsby";
import {
  SbGatsbyStory,
  useStoryblokState,
  StoryblokComponent,
  SbBlokData,
  storyblokEditable,
} from "gatsby-source-storyblok";

import Layout from "../components/Layout";
import { SEO } from "../components/Seo";

type Props = {
  storyblokEntry: SbGatsbyStory;
};

function PageTemplate({ data: { storyblokEntry } }: PageProps<Props>) {
  const story = useStoryblokState(storyblokEntry);

  const components = (story.content.body || []).map((blok: SbBlokData) => (
    <StoryblokComponent blok={blok} key={blok._uid} />
  ));

  return (
    <Layout useFloatingHeader={story.content.useFloatingHeader}>
      <div {...storyblokEditable(story.content)}>{components}</div>
    </Layout>
  );
}

export default PageTemplate;

export const Head = ({ data: { storyblokEntry } }: PageProps<Props>) => {
  const story = useStoryblokState(storyblokEntry);
  const title = story.content.seoTitle || storyblokEntry.name;
  return (
    <SEO
      title={title}
      description={story.content.seoDescription}
      imageUrl={story.content.seoImage?.filename}
    />
  );
};

export const query = graphql`
  query PageQuery($full_slug: String!) {
    storyblokEntry(full_slug: { eq: $full_slug }) {
      content
      id
      internalId
      name
    }
  }
`;
