import React, { useCallback, FormEvent, useState } from "react";
import { object, string } from "yup";
import { navigate } from "gatsby";

import {
  VStack,
  FormControl,
  FormLabel,
  Text,
  Input,
  Textarea,
  Button,
  Box,
} from "@chakra-ui/react";

const FORM_NAME = "contact";

type Props = {};

type ContactSubmission = {
  [key: string]: string;
  email: string;
  name: string;
  message: string;
  "form-name": string;
};

function encode(data: ContactSubmission): string {
  return Object.keys(data)
    .map(
      (key: keyof typeof data) =>
        encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
    )
    .join("&");
}

const formSchema = object({
  name: string().required(),
  email: string().email().required(),
  message: string().required(),
});

function ContactForm({}: Props) {
  const [name, setName] = useState<ContactSubmission["name"]>("");
  const [email, setEmail] = useState<ContactSubmission["email"]>("");
  const [message, setMessage] = useState<ContactSubmission["message"]>("");
  const [error, setError] = useState<any>(null);
  const isValid: boolean = formSchema.isValidSync({
    name,
    email,
    message,
  });

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      try {
        event.preventDefault();
        await fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({
            "form-name": FORM_NAME,
            email,
            name,
            message,
          }),
        });
        navigate("/thank-you/");
      } catch (e: any) {
        setError(e);
      }
    },
    [name, email, message, navigate]
  );

  return (
    <form
      method="post"
      netlify-honeypot="bot-field"
      data-netlify="true"
      name={FORM_NAME}
      onSubmit={handleSubmit}
    >
      <VStack>
        <FormControl isRequired>
          <FormLabel>Name</FormLabel>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            name="name"
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Email address</FormLabel>
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            name="email"
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Message</FormLabel>
          <Textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows={8}
            name="message"
          />
        </FormControl>
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        {error && <Text>{error}</Text>}
        <Box alignContent="flex-start" w="100%">
          <Button disabled={!isValid} type="submit" colorScheme="black">
            Submit
          </Button>
        </Box>
      </VStack>
    </form>
  );
}

export default ContactForm;
