import React from "react";
import { Box, Text, Icon, Link, Container, HStack } from "@chakra-ui/react";
import {
  FaYoutube,
  FaTwitter,
  FaFacebook,
  FaSoundcloud,
  FaInstagram,
} from "react-icons/fa";

import { useSiteMetadata } from "../hooks/useSiteMetadata";

type Props = {};

const iconSize = [8, 16];

function Footer({}: Props) {
  const { twitter, youtube, facebook, soundcloud, instagram, title } =
    useSiteMetadata();
  return (
    <Box textStyle="footer" background="nearBlack" p="8" width="100%">
      <Container>
        <HStack
          direction={"row"}
          spacing={4}
          align="center"
          justify="space-between"
          width="100%"
        >
          <Box>
            <Link href={youtube}>
              <Icon
                color="brandYellow"
                as={FaYoutube}
                w={iconSize}
                h={iconSize}
              />
            </Link>
          </Box>
          <Box>
            <Link href={twitter}>
              <Icon
                color="brandYellow"
                as={FaTwitter}
                w={iconSize}
                h={iconSize}
              />
            </Link>
          </Box>
          <Box>
            <Link href={facebook}>
              <Icon
                color="brandYellow"
                as={FaFacebook}
                w={iconSize}
                h={iconSize}
              />
            </Link>
          </Box>
          <Box>
            <Link href={soundcloud}>
              <Icon
                color="brandYellow"
                as={FaSoundcloud}
                w={iconSize}
                h={iconSize}
              />
            </Link>
          </Box>
          <Box>
            <Link href={instagram}>
              <Icon
                color="brandYellow"
                as={FaInstagram}
                w={iconSize}
                h={iconSize}
              />
            </Link>
          </Box>
        </HStack>
        <Box p={12}>
          <Text as="p" textAlign="center">
            <Text as="span" textAlign="center">
              {new Date().getFullYear()}{" "}
            </Text>
            <Text as="span" color="brandYellow">
              {" "}
              |{" "}
            </Text>
            <Text as="span">{title}</Text>
          </Text>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
