import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from "gatsby-source-storyblok";
import React, { useState, useCallback, useEffect } from "react";
import { Box, Flex, Collapse, Button } from "@chakra-ui/react";

type Props = {
  blok: SbBlokData & {
    content: SbBlokData[];
    initiallyOpen: boolean;
    buttonAlignment: "flex-start" | "flex-end" | "center";
  };
};

function ToggledContent({ blok }: Props) {
  const [isOpen, setIsOpen] = useState(blok.initiallyOpen);
  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [setIsOpen, isOpen]);

  useEffect(() => {
    setIsOpen(blok.initiallyOpen);
  }, [blok.initiallyOpen]);
  return (
    <Box>
      <Collapse in={isOpen} animateOpacity>
        <Box {...storyblokEditable(blok)} textStyle="m">
          {blok.content.map((c) => (
            <StoryblokComponent key={c._uid} blok={c} />
          ))}
        </Box>
      </Collapse>
      <Flex
        alignContent={"center"}
        align="center"
        justify={blok.buttonAlignment}
        w="100%"
      >
        <Button variant="outline" onClick={handleToggle}>
          Show {isOpen ? "less" : "more"}
        </Button>
      </Flex>
    </Box>
  );
}

export default ToggledContent;
