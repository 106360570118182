import React from "react";
import { useSiteMetadata } from "../hooks/useSiteMetadata";
import { getSbImageUrl } from "gatsby-plugin-storyblok-image";

type SEOProps = {
  title?: string;
  description?: string;
  pathname?: string;
  imageUrl?: string;
};

export const SEO: React.FC<React.PropsWithChildren<SEOProps>> = ({
  title,
  description,
  pathname,
  children,
  imageUrl,
}) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    image,
    siteUrl,
    twitterUsername,
    title: siteTitle,
  } = useSiteMetadata();

  const seo = {
    title: `${title || defaultTitle} | ${siteTitle}`,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname || ``}`,
    twitterUsername,
  };

  const sbImageUrl = imageUrl
    ? getSbImageUrl(imageUrl, {
        quality: 80,
        fitIn: true,
        width: 1200,
        height: 627,
      })
    : undefined;

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:creator" content={seo.twitterUsername} />
      <meta name="og:type" content="website" />
      <meta name="og:title" content={seo.title} />
      <meta name="og:description" content={seo.description} />
      {(imageUrl || image) && (
        <meta name="og:image" content={imageUrl || image} />
      )}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "EntertainmentBusiness",
            "@id": "https://www.elementrecordingstudios.com",
            "name": "Element Recording Studios",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "400 N 5th St",
              "addressLocality": "Kansas City",
              "addressRegion": "KS",
              "postalCode": "66101",
              "addressCountry": "US"
            },
            "url": "https://www.elementrecordingstudios.com",
            "telephone": "+19136455961",
          }
      `}
      </script>
      {children}
    </>
  );
};
