import React from "react";
import { Image, Box, BoxProps } from "@chakra-ui/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImage } from "gatsby-plugin-storyblok-image";
import { StoryblokImage } from "../types/StoryblokImage";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

type Props = {
  imgWrapperProps?: BoxProps;
  imgStyle?: React.CSSProperties;
  blok: {
    image: StoryblokImage;
    imageFit?: "cover" | "contain";
    allowZoom: boolean;
  };
};

function DynamicImage({ blok, imgWrapperProps, imgStyle }: Props) {
  const { imageFit, image, allowZoom } = blok;
  const isSvg = image?.filename.endsWith(".svg");

  const gatsbyImageData = isSvg
    ? null
    : getGatsbyImage(image?.filename, {
        layout: "constrained",
        quality: 80,
      });

  const Wrapper = allowZoom ? Zoom : Box;

  return (
    <Wrapper>
      <Box {...imgWrapperProps}>
        {gatsbyImageData ? (
          <GatsbyImage
            alt={image.alt}
            image={gatsbyImageData}
            objectFit={imageFit}
            style={{ height: "100%" }}
            imgStyle={imgStyle}
          />
        ) : (
          <Image src={image.filename} alt={image.alt} />
        )}
      </Box>
    </Wrapper>
  );
}

export default DynamicImage;
