import React from "react";
import {
  Flex,
  HStack,
  VStack,
  IconButton,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useDisclosure,
  ModalHeader,
  Box,
} from "@chakra-ui/react";
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import { useSiteMetadata } from "../hooks/useSiteMetadata";
import { graphql, useStaticQuery } from "gatsby";
import { SbGatsbyStory } from "gatsby-source-storyblok";
import { StoryblokLink } from "../types/StoryblokLink";
import { StaticImage } from "gatsby-plugin-image";

import SmartLink from "./SmartLink";

type Props = {
  isFloating?: boolean;
};

type MenuItem = {
  text: string;
  link: StoryblokLink;
  _uid: string;
};

interface MainMenuStory extends SbGatsbyStory {
  items?: MenuItem[];
}

function SiteHeader({ isFloating }: Props) {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const { title } = useSiteMetadata();
  const { storyblokEntry } = useStaticQuery(graphql`
    query SiteHeaderQuery {
      storyblokEntry(full_slug: { eq: "main-menu" }) {
        id
        content
        internalId
      }
    }
  `);

  const story: MainMenuStory = {
    ...storyblokEntry,
    content: JSON.parse(storyblokEntry.content),
  };

  return (
    <>
      <Flex
        position={isFloating ? "absolute" : "relative"}
        justify="space-between"
        align="center"
        zIndex={10}
        direction="row"
        width="100%"
        py={[4, 12]}
        px={[4, 8]}
        textStyle={isFloating ? "floatingMenu" : "menu"}
      >
        <Box w={["40px", "80px"]} h={["40px", "80px"]} className="logo-wrapper">
          <SmartLink
            to={{
              linktype: "url",
              cached_url: "/",
              url: "/",
              id: "home",
              fieldtype: "url",
            }}
          >
            <StaticImage
              src="../images/logo.jpg"
              alt="Element Recording Studios logo"
              layout="fixed"
              width={80}
              height={80}
              style={{ width: "100%", height: "100%" }}
              imgStyle={{ width: "100%", height: "100%" }}
            />
          </SmartLink>
        </Box>
        <HStack as="nav" display={["none", "flex"]} spacing={8}>
          {(story.content.items || []).map((x: MenuItem) => (
            <SmartLink key={x._uid} to={x.link}>
              {x.text}
            </SmartLink>
          ))}
        </HStack>
        <IconButton
          display={["inherit", "none"]}
          colorScheme="brandAlpha"
          aria-label={isOpen ? "Close navigation" : "Open navigation"}
          onClick={onToggle}
          icon={
            isOpen ? (
              <CloseIcon w={"24px"} h={"24px"} color="brandYellow" />
            ) : (
              <HamburgerIcon h="32px" w="32px" color="brandYellow" />
            )
          }
        />
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalHeader>Hello</ModalHeader>
        <ModalContent background="nearBlack" textStyle={"mobileMenu"}>
          <ModalCloseButton color="brandYellow" h="32px" w="32px" size="32px" />
          <ModalBody py={12}>
            <VStack as="nav" height="100%" align="center">
              {(story.content.items || []).map((x: MenuItem) => (
                <SmartLink key={x._uid} to={x.link}>
                  {x.text}
                </SmartLink>
              ))}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default SiteHeader;
