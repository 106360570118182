import React from "react";
import { SimpleGrid, Box, Text, useToken } from "@chakra-ui/react";
import { SbBlokData, storyblokEditable } from "gatsby-source-storyblok";
import { toSpacingValue } from "../util/toSpacingValue";

import DynamicImage from "./DynamicImage";
import { StoryblokImage } from "../types/StoryblokImage";

type SizeOption =
  | "default"
  | "xsmall"
  | "small"
  | "medium"
  | "large"
  | "xlarge"
  | "xxlarge";

type ImageGridProps = {
  images: StoryblokImage[];
  columnCount: number;
  mobileColumnCount: number;
  spacingX: SizeOption;
  spacingY: SizeOption;
  allowZoom: boolean;
  marginX: string;
  marginY: string;
  paddingX: string;
  paddingY: string;
};

type ImageGridBlok = SbBlokData & ImageGridProps;

type Props = {
  blok: ImageGridBlok;
};

function ImageGrid({ blok }: Props) {
  const {
    columnCount,
    mobileColumnCount = 1,
    spacingX,
    spacingY,
    allowZoom,
    marginX,
    marginY,
    paddingX,
    paddingY,
  } = blok;
  const spacingXValue = toSpacingValue(spacingX);
  const [gridGapX] = useToken("space", [spacingXValue[1]]);

  return (
    <SimpleGrid
      {...storyblokEditable(blok)}
      columns={[mobileColumnCount, columnCount]}
      spacingX={toSpacingValue(spacingX)}
      spacingY={toSpacingValue(spacingY)}
      mx={toSpacingValue(marginX)}
      my={toSpacingValue(marginY)}
      px={toSpacingValue(paddingX)}
      py={toSpacingValue(paddingY)}
    >
      {blok.images.map(
        (image: StoryblokImage): JSX.Element => (
          <Box key={image.id}>
            <DynamicImage
              imgWrapperProps={{
                h: ["auto", `calc(${100 / columnCount}vw - ${gridGapX})`],
              }}
              blok={{ image, allowZoom, imageFit: "cover" }}
            />
            {(image.name || image.title || image.alt) && (
              <Box py={2}>
                <Text fontSize="sm" textAlign="center">
                  {image.name || image.title || image.alt}
                </Text>
              </Box>
            )}
          </Box>
        )
      )}
    </SimpleGrid>
  );
}

export default ImageGrid;
