import React from "react";
import { Box, Text, Image, LinkBox } from "@chakra-ui/react";
import { SbBlokData, storyblokEditable } from "gatsby-source-storyblok";
import { Link as GatsbyLink } from "gatsby";

type Image = {
  filename: string;
  alt: string;
  copyright: string;
  fieldtype: string;
  focus: string;
  id: number;
  name: string;
  title: string;
};

type StoryblokLink = {
  cached_url: string;
  fieldtype: string;
  linktype: string;
  id: string;
  url: string;
};

type Props = {
  blok: SbBlokData & {
    image: Image;
    link: StoryblokLink;
    text: string;
  };
};

function ImageBackgroundLink({ blok }: Props) {
  const { text, image, link } = blok;

  return (
    <LinkBox
      className="hover-link-box"
      pos="relative"
      {...storyblokEditable(blok)}
    >
      <Image
        objectFit={"cover"}
        width="100%"
        height="100%"
        src={image.filename}
        alt={image.alt}
        sx={{
          opacity: 0.3,
          transition: "0.3s all",
          ".hover-link-box:hover &": {
            opacity: 1,
          },
        }}
      />
      <Box
        pos="absolute"
        display="flex"
        zIndex={1}
        alignItems="center"
        justifyContent="center"
        top={0}
        left={0}
        w="100%"
        h="100%"
      >
        <GatsbyLink
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          to={`/${link.cached_url || link.url}`}
        >
          <Text
            sx={{
              transition: "0.3s all",
              transform: "translateY(0)",
              ".hover-link-box:hover &": {
                opacity: 0,
                transform: "translateY(-50%)",
              },
            }}
            fontSize="4xl"
            fontWeight={700}
          >
            {text}
          </Text>
        </GatsbyLink>
      </Box>
    </LinkBox>
  );
}

export default ImageBackgroundLink;
