import { SbBlokData, storyblokEditable } from "gatsby-source-storyblok";
import React from "react";
import RichText from "./RichText";
import { Box } from "@chakra-ui/react";

type Props = {
  blok: SbBlokData & {
    textAlignment?: "center" | "left" | "right";
    truncateAtLine?: number;
    allowExpand?: boolean;
  };
};

function TextBlock({ blok }: Props) {
  return (
    <Box {...storyblokEditable(blok)} textStyle="m">
      <RichText
        body={blok.body}
        textAlignment={blok.textAlignment}
        allowExpand={blok.allowExpand}
        truncateAtLine={blok.truncateAtLine}
      />
    </Box>
  );
}

export default TextBlock;
