import { SbBlokData, StoryblokComponent } from "gatsby-source-storyblok";
import { Container, Box } from "@chakra-ui/react";
import React from "react";

import { toSpacingValue } from "../util/toSpacingValue";

type SizeOption =
  | "default"
  | "xsmall"
  | "small"
  | "medium"
  | "large"
  | "xlarge"
  | "xxlarge";

type Props = {
  blok: SbBlokData & {
    content: SbBlokData[];
    paddingX: SizeOption;
    paddingY: SizeOption;
    marginX: SizeOption;
    marginY: SizeOption;
    enforceMaxWidth: boolean;
    centerContent: boolean;
    centerSelf: boolean;
  };
};

function DynamicContainer({ blok }: Props) {
  const {
    maxW,
    centerContent,
    enforceMaxWidth,
    marginX,
    marginY,
    paddingX,
    paddingY,
    centerSelf,
  } = blok;
  return (
    <Box
      alignItems={centerContent ? "center" : "flex-start"}
      justifyContent={centerContent ? "center" : "flex-start"}
      display={centerContent ? "flex" : undefined}
      maxW={enforceMaxWidth ? "60ch" : "none"}
      mx={centerSelf ? "auto" : toSpacingValue(marginX)}
      my={toSpacingValue(marginY)}
      px={toSpacingValue(paddingX)}
      py={toSpacingValue(paddingY)}
    >
      {blok.content.map((content) => (
        <StoryblokComponent key={content._uid} blok={content} />
      ))}
    </Box>
  );
}

export default DynamicContainer;
