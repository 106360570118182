import React from "react";
import { SimpleGrid } from "@chakra-ui/react";
import {
  SbBlokData,
  storyblokEditable,
  StoryblokComponent,
} from "gatsby-source-storyblok";
import { toSpacingValue } from "../util/toSpacingValue";

type SizeOption =
  | "default"
  | "xsmall"
  | "small"
  | "medium"
  | "large"
  | "xlarge"
  | "xxlarge";

type GridProps = {
  columns: {}[];
  columnCount: number;
  mobileColumnCount: number;
  spacingX: SizeOption;
  spacingY: SizeOption;
  paddingX: SizeOption;
  paddingY: SizeOption;
  marginX: SizeOption;
  marginY: SizeOption;
  horizontalAlignment: AlignSetting;
};

type Props = {
  blok: SbBlokData & GridProps;
};

function Grid({ blok }: Props) {
  const {
    columnCount,
    mobileColumnCount = 1,
    spacingX,
    spacingY,
    marginX,
    marginY,
    paddingX,
    paddingY,
    horizontalAlignment = "start",
  } = blok;
  return (
    <SimpleGrid
      {...storyblokEditable(blok)}
      columns={[mobileColumnCount, columnCount]}
      spacingX={toSpacingValue(spacingX)}
      spacingY={toSpacingValue(spacingY)}
      mx={toSpacingValue(marginX)}
      my={toSpacingValue(marginY)}
      px={toSpacingValue(paddingX)}
      py={toSpacingValue(paddingY)}
      style={{ justifyItems: horizontalAlignment }}
    >
      {blok.columns.map(
        (component: SbBlokData): JSX.Element => (
          <StoryblokComponent key={component._uid} blok={component} />
        )
      )}
    </SimpleGrid>
  );
}

export default Grid;
