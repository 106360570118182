import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { storyblokInit } from "gatsby-source-storyblok";
import { Box } from "@chakra-ui/react";

import Footer from "./Footer";

import "@fontsource/work-sans/400.css";
import "@fontsource/work-sans/700.css";

import { theme } from "./theme";
import Grid from "../components/Grid";
import SiteHeader from "../components/SiteHeader";
import DynamicImage from "./DynamicImage";
import TextBlock from "../components/TextBlock";
import ImageSlider from "../components/ImageSlider";
import ImageBackgroundLink from "../components/ImageBackgroundLink";
import DynamicEmbeddableContent from "../components/DynamicEmbeddableContent";
import DynamicButton from "../components/DynamicButton";
import DynamicCenter from "../components/DynamicCenter";
import DynamicContainer from "../components/DynamicContainer";
import StaffMember from "../components/StaffMember";
import ContactForm from "../components/ContactForm";
import ToggledContent from "../components/ToggledContent";
import ImageGrid from "../components/ImageGrid";

storyblokInit({
  accessToken: process.env.GATSBY_STORYBLOK_TOKEN,
  // bridge: false,
  // apiOptions: {  },
  components: {
    grid: Grid,
    textBlock: TextBlock,
    imageSlider: ImageSlider,
    imageBackgroundLink: ImageBackgroundLink,
    button: DynamicButton,
    center: DynamicCenter,
    container: DynamicContainer,
    staffMember: StaffMember,
    contactForm: ContactForm,
    image: DynamicImage,
    embeddableContent: DynamicEmbeddableContent,
    toggledContent: ToggledContent,
    imageGrid: ImageGrid,
  },
});

type Props = {
  children: JSX.Element;
  useFloatingHeader?: boolean;
};

function Layout({ children, useFloatingHeader }: Props) {
  return (
    <ChakraProvider theme={theme}>
      <Box background="beige">
        <SiteHeader isFloating={useFloatingHeader} />
        <Box>{children}</Box>
        <Footer />
      </Box>
    </ChakraProvider>
  );
}

export default Layout;
