import React, { useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from "gatsby-source-storyblok";

import { Box, Text, useToken, useMediaQuery } from "@chakra-ui/react";
import SliderImage from "./SliderImage";
import "@splidejs/react-splide/css";

import { StoryblokImage } from "../types/StoryblokImage";

type FlexAlignment = "center" | "flex-start" | "flex-end";

type Props = {
  blok: SbBlokData & {
    images: StoryblokImage[];
    fullSizeImage: boolean;
    imageFit?: "contain" | "cover";
    height?: "auto" | "100vh" | "md" | "lg" | "xl" | "2xl" | "3xl";
    mobileHeight?: "auto" | "100vh" | "md" | "lg" | "xl" | "2xl" | "3xl";
    contentOverlay: SbBlokData[];
    contentOverlayAlign: FlexAlignment;
    contentOverlayJustify: FlexAlignment;
    autoplay: boolean;
    interval: number;
    type: "slide" | "fade";
    showArrows: boolean;
    showPagination: boolean;
    rewind: boolean;
    speed: number;
    allowZoom: boolean;
  };
};

function ImageSlider({ blok }: Props) {
  const {
    contentOverlay = [],
    contentOverlayAlign = "center",
    contentOverlayJustify = "center",
    allowZoom,
    height,
    mobileHeight,
  } = blok;

  const [mobileBreakpoint] = useToken("breakpoints", ["sm"]);
  const [activeSlide, setActiveSlide] = useState<number>(0);

  const [isMobile] = useMediaQuery(`(max-device-width: ${mobileBreakpoint})`);
  const caption =
    blok.images[activeSlide]?.name ||
    blok.images[activeSlide]?.title ||
    blok.images[activeSlide].alt;

  return (
    <Box
      {...storyblokEditable(blok)}
      pos="relative"
      height={[mobileHeight, height]}
    >
      {contentOverlay.length > 0 && (
        <Box
          pos="absolute"
          display="flex"
          zIndex={1}
          alignItems={contentOverlayAlign}
          justifyContent={contentOverlayJustify}
          px={[2, 32]}
          top={0}
          left={0}
          w="100%"
          h="100%"
        >
          {contentOverlay.map((content: SbBlokData) => (
            <StoryblokComponent key={content._uid} blok={content} />
          ))}
        </Box>
      )}

      {caption && (
        <Box
          pos="absolute"
          display="flex"
          zIndex={2}
          alignItems="center"
          justifyContent="center"
          bottom={8}
          left={0}
          w="100%"
        >
          <Box bg="blackAlpha.600" px={4} py={2} borderRadius="full">
            <Text color="whiteAlpha.900">{caption}</Text>
          </Box>
        </Box>
      )}

      <Splide
        options={{
          autoplay: blok.autoplay,
          interval: blok.interval,
          type: blok.type,
          width: height === "100vh" ? "100vw" : "auto",
          height: isMobile ? mobileHeight : height,
          arrows: blok.showArrows,
          pagination: blok.showPagination,
          rewind: blok.rewind,
          speed: blok.speed,
          rewindSpeed: blok.speed,
          pauseOnHover: false,
        }}
        onMove={(_splide, newIndex: number) => {
          setActiveSlide(newIndex);
        }}
      >
        {blok.images.map((image) => {
          return (
            <SplideSlide key={image.id}>
              <SliderImage
                fullSizeImage={blok.fullSizeImage}
                image={image}
                allowZoom={allowZoom}
              ></SliderImage>
            </SplideSlide>
          );
        })}
      </Splide>
    </Box>
  );
}

export default ImageSlider;
